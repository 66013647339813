<template>
    <div>
        <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="">
        <CRow>
        <CCol col="6" xl="6">
           <h6 class="w-100">Domov  >  Obsah</h6>
            <h1 class="w-100">{{thistype}}</h1>
        </CCol>
        <CCol col="6" xl="6">
             <div class="text-right system-options w-100 d-block" style="">
                   <router-link :to="'/add-content/' + id" v-if="id"  class="ml-3" :href="'/add-content/' + id">
                            <template>
                                <a-button type="primary"> Nový obsah</a-button>
                            </template>
                        </router-link>
                <router-link :to="'/content-type/' + slugfields" v-if="this.id" class="ml-2" :href="'/content-type/' + slugfields">
                    <template>
                        <a-button type="secondary" > Nastavenia</a-button>
                    </template>
                </router-link>
                <a-button type="secondary" v-if="id == 'book'" class="ml-2" @click="openreceipt"><i class="fas fa-plus-circle mr-2"></i> Príjemka</a-button>

                     <a-button type="secondary" v-if="id == 'book'" @click="partner = true"><i class="fas fa-cogs pr-1"></i> Nahrať knihu Partner</a-button>


            </div>
        </CCol>
        <CCol col="12" xl="12">
          <CRow>
          <template v-for="cc in mainCtype.fields">
            <template v-if="mainCtype.options[cc.id]">
            <CCol col="3" :key="'filtertypecontent' + cc.id">
                <a-form-item :label="cc.title" class="mb-0">
                  <a-select v-model="ff[cc.id]"  show-search :filter-option="(e,f) => compareData(e,f,cc.id)" :options="mainCtype.options[cc.id]" mode="multiple" class="w-100" ></a-select>
                </a-form-item>
          </CCol>
        </template>
          </template>
          <a-button @click="applyFilter">Filtrovať</a-button>
        </CRow>

        </CCol>
        </CRow>
        </CCardHeader>
        <CCardBody>
            <div class="row">
                <div class="col">
                    <div class="">
                        <div class="table-responsive">
                            <a-table :row-selection="rowSelection"
                                    :columns="columns"
                                     :pagination="pagination"
                                    :rowKey="record => record.id"
                                    :dataSource="data"
                                    :key="tableKey"
                                    :loading="loading"
                                    @change="handleTableChange"
                                    class="table-flush">

                                <template slot="url" slot-scope="url,record">
                                    <a v-if="url" :href="vars.site_url + '/' + record.language + '/' + url" target="_blank" style="color:#3c3c3c;max-width:200px;display:inline-block;overflow:hidden;" class="">{{url}}</a>
                                    <span v-else><i class="fa fa-times"></i></span>
                                </template>
                                <template slot="field_url" slot-scope="url,record">
                                <a v-if="url" :href="vars.site_url + '/' + record.language + '/' + url" target="_blank" style="color:#3c3c3c;max-width:200px;display:inline-block;overflow:hidden;" class="">{{url}}</a>
                                <span v-else><i class="fa fa-times"></i></span>
                            </template>
                                <template slot="field_4" slot-scope="text">
                                     <a-popover  v-if="text" >
                                        <template slot="content">
                                          <span v-html="text"></span>
                                        </template>
                                        <a-button type="primary">
                                            <i class="fad fa-align-left"></i>
                                        </a-button>
                                    </a-popover>
                                    <span v-else><i class="fa fa-times"></i></span>
                                </template>
                                <template slot="field_color" slot-scope="color">
                                    <span class="square" :style="'background-color:' + color" :title="color"></span>
                                </template>
                                <template slot="field_1" slot-scope="title,record,third,fourth" >
                                  <div @dblclick="editThisField(fourth.dataIndex,record.id,title,third)">
                                    <a v-if="record.url" :href="vars.site_url + '/' + record.language + '/' + record.url" target="_blank" style="color:#3c3c3c" class=""><span v-if="title">{{title}}</span><span v-else><i class="fa fa-times"></i></span></a>
                                    <span v-else><span v-if="title">{{title}}</span><span v-else><i class="fa fa-times"></i></span></span>
                                  </div>
                                </template>
                                <template slot="field_10" slot-scope="builder">
                                    <template>
                                    <span v-if="builder != '[]'"><i class="fa fa-check"></i></span>
                                    <span v-else><i class="fa fa-times"></i></span>
                                  </template>



                                </template>
                                <template slot="field_12" slot-scope="fieldoption">
                                    <div v-if="fieldoption">
                                    {{fieldoption}}
                                    </div>
                                    <span v-else><i class="fa fa-times"></i></span>
                                </template>
                                <template slot="field_14" slot-scope="datefield">
                                    <div v-if="datefield">
                                        {{moment.unix(datefield).format('D.M.YYYY')}}
                                    </div>
                                    <span v-else><i class="fa fa-times"></i></span>
                                </template>
                                <template slot="field_16" slot-scope="datefield">
                                    <div v-if="datefield">
                                      {{moment.unix(datefield[0][0]).format('D.M.YYYY')}} - {{moment.unix(datefield[0][1]).format('D.M.YYYY')}}
                                    </div>
                                    <span v-else><i class="fa fa-times"></i></span>
                                </template>
                                <template slot="datetime" slot-scope="datefield">
                                    <div v-if="datefield">
                                      {{moment(datefield).format('DD.MM.YYYY HH:mm')}}
                                    </div>
                                    <span v-else><i class="fa fa-times"></i></span>
                                </template>
                                <template slot="field_8" slot-scope="datefield">
                                <div v-if="datefield">
                                   {{datefield}}
                                </div>
                                <span v-else><i class="fa fa-times"></i></span>
                            </template>
                                <template slot="field_2" slot-scope="datefield">
                                    <div v-if="datefield">
                                        {{datefield}}
                                    </div>
                                    <span v-else><i class="fa fa-times"></i></span>
                                </template>
                                <template slot="field_3" slot-scope="image">
                                   <template v-if="image"> <img v-if="JSON.parse(image).uri" :src="IMG_URL + 'suggestions' + JSON.parse(image).uri" width="50"></template>
                                    <span v-else><i class="fa fa-times"></i></span>
                                </template>
                                <template slot="field_11" slot-scope="image,record">
                                   <div style="max-width:130px">
                                    <a-carousel arrows>
                                        <div
                                                slot="prevArrow"
                                                class="custom-slick-arrow"
                                                style="left: 10px;zIndex: 1"
                                        >
                                            <i class="fal fa-chevron-left"></i>
                                        </div>
                                        <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
                                            <i class="fal fa-chevron-right"></i>
                                        </div>
                                        <div :key="'g' + record.id + iie" v-for="(img,iie) in image" class="">
                                            <img v-if="img.image.uri" :src="IMG_URL + 'suggestions' + img.image.relative">
                                        </div>
                                    </a-carousel>
</div>
                                </template>
                                <template slot="field_5" slot-scope="number">
                                    <span v-if="number">{{number}}</span>
                                    <span v-else><i class="fa fa-times"></i></span>
                                </template>
                                <template slot="field_7" slot-scope="published">
                                 <span v-if="published"><i class="fa fa-check"></i></span>
                                    <span v-if="!published"><i class="fa fa-times"></i></span>
                                    <span v-if="null"></span>

                                </template>

                                <template slot="name" slot-scope="user" styyle="background-color:red">
                                   <span class="actions">

                                       <router-link :to="'/edit-content/' + user.id" class="btn text-default btn-sm" :href="'/edit-content/' + user.id">
                                        <span class="btn-inner--icon" style="margin-right:5px"><span class="v-btn__content" data-no-activator=""><svg class="pc-icon" style="width: 20px; height: 20px;"><use xlink:href="/assets/svg/sprite.svg#custom-edit-outline"></use></svg></span></span></router-link>
                                    <a-popconfirm placement="topRight"
                                                  @confirm="confirm(user.id)"
                                                  @cancel="cancel"
                                                  okText="Áno"
                                                  cancelText="Nie">
                                        <template slot="title">
                                            <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                            Položka bude trvalo odstránená z vašej stránky.
                                        </template>
                                        <a-button type="link" textColor="default" title="Zmazať"><span class="v-btn__content" data-no-activator=""><svg class="pc-icon" style="width: 20px; height: 20px;"><use xlink:href="/assets/svg/sprite.svg#custom-trash"></use></svg></span></a-button>
                                    </a-popconfirm>
                                   </span>
                                </template>

                            </a-table>
                           <div  style="bottom:0" class="action-panel w-25 m-3 float-left"  v-if="rowSelection.selectedRowKeys.length > 0">
                                <a-select style="width:150px;"  class="mr-3" size="small" placeholder="Vyberte si akciu" v-model="action" :options="actions"></a-select>

                                <a-select  v-if="action == 'multiedit'" mode="multiple" style="width:150px;"  class="mr-3" size="small" placeholder="Vyberte si polia" v-model="actionFields"  :options="allfields"></a-select>

<template v-if="action != 'multiedit'">
                                <a-popconfirm placement="topRight"
                                              @confirm="doAction"
                                              @cancel="cancel"
                                              okText="Áno"
                                              cancelText="Nie"
                                >
                                    <template slot="title">
                                        <h5 color="danger" >Prajete si vykonať tento príkaz?</h5>
                                        Príkaz ovplyvní {{rowSelection.selectedRowKeys.length}} <span v-if="rowSelection.selectedRowKeys.length === 1">stránku</span> <span v-if="rowSelection.selectedRowKeys.length > 1 && rowSelection.selectedRowKeys.length < 5">stránky</span> <span v-if="rowSelection.selectedRowKeys.length > 4">stránok</span>.
                                    </template>
                                   <a-button type="warning">Vykonať</a-button>
                                </a-popconfirm>
                            </template>
                            <template v-else>
                                <a-button type="secondary" @click="multiedit">Do úprav</a-button>
                            </template>
                            </div>
                        </div>
                        <a-modal :title="false"  centered  v-model="simpleModal" v-if="simpleModal" @ok="updateValNid">
                            <a-form-item :label="fieldLabel" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
                                <a-input v-model="inputValue" ref="myField" :autoFocus="true" @pressEnter="updateValNid"/>
                            </a-form-item>
                        </a-modal>

                        <a-modal title="Nová príjemka" centered  v-model="reciept" v-if="reciept" @ok="sendReciept">
                            <a-form-item label="Vstup"  :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
                                <a-input-search v-model="addbook" @search="findItAll" placeholder="Vyhľadávanie podľa EAN"  />
                            </a-form-item>
                            <div class="searchoutput" v-if="searchResults.length > 0">
                    <a-list size="small" bordered :data-source="searchResults">
                        <a-list-item slot="renderItem" slot-scope="item">
                           <router-link v-if="item.bundle === 'node'" :to="'/edit-content/' + item.entity_id"  :class="'nav-link bundle-' + item.bundle"  :href="'/edit-content/' + item.entity_id"> {{ item.value }}</router-link>
                           <router-link v-if="item.bundle === 'taxonomy'" :to="'/edit-taxonomy-item/' + item.entity_id + '/' + item.content_type"  :class="'nav-link bundle-' + item.bundle"  :href="'/edit-taxonomy-item/' + item.entity_id + '/' + item.content_type"> {{item.voc}} / {{ item.value }}</router-link>
                        </a-list-item>
                    </a-list>
                </div>

                            </a-modal>


                        <a-modal title="Nová kniha Partner Technik" centered  v-model="partner" v-if="partner" @ok="sendPartnerBook">
                            <a-form-item label="Vstup"  :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
                                <a-textarea class="form-control-compact"  v-model="partnerBookData" />
                            </a-form-item>
                            </a-modal>


                    </div>
                </div>
            </div>
        </CCardBody>
      </CCard>
    </CCol>
        </CRow>
    </div>
</template>
<script>
    import {getURL} from "../api";
     import {dataService} from "../_services";
    import moment from 'moment';
    import {authHeader} from "../_helpers";
    import locale from 'ant-design-vue/es/date-picker/locale/sk_SK';
    import {IMG_URL} from "../api";
    import {Button,Select, Popconfirm,Popover,Carousel,Modal} from 'ant-design-vue';

    const columns = [
        {
            title: 'Názov',
            dataIndex: 'title',
            sorter: true,
            width: '20%',
        },
        {
            title: 'Vytvorené',
            dataIndex: 'created',
        },
        {
            title: 'Jazyk',
            scopedSlots: {customRender: 'language'},
            dataIndex: 'language',
        },
        {
            title: 'Url',
            dataIndex: 'url',
            scopedSlots: {customRender: 'url'},
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];
    export default {
        name: "allitems",
        props: ["id","tid"],
        components: {
            "a-button": Button,
            "a-select": Select,
            "a-popconfirm": Popconfirm,
            "a-popover": Popover,
            "a-carousel": Carousel,
              "a-modal": Modal,
        },
        data() {
            return {
                data: [],
                vars:{},
                colorsto: JSON.parse(localStorage.colors),
               url: getURL(),
                ctt: [],
                loading: false,
                tht:false,
                head: authHeader(),
                contenttypes:[],
                partner:false,
                ff:{},
                partnerBookData:"",
                locale,
                moment,
                pagination: {
                       hideOnSinglePage: false,
                    pageSize: 30, // default number of pages per page
                    showSizeChanger: true, // display can change the number of pages per page
                    pageSizeOptions: ['10', '20', '30', '40', '60', '100', '1000'], // number of pages per option
                    showTotal: total => `Položiek: ${total}`, // show total
                    showSizeChange: (current, pageSize) => {  this.pageSize = pageSize; }, // update display when changing the number of pages per page
                },
                fieldtitle:"",
                visible: false,
                filterBy:[],
                tableKey:0,
                realFilter:JSON.parse(localStorage.filter),
                perm:JSON.parse(localStorage.user).data.paths,
                filterValues:{},
                actions: [{"value":"delete","label":"Zmazať"},{"value": "export","label": "export"},{"value": "publish","label": "publikovať / odpublikovať"},{"value": "multiedit","label": "hromadná úprava"}],
                thistype:"",
                selectedRowKeys: [],
                action:undefined,
                selectValue:[],
                actionFields:[],
                IMG_URL,
                searchResults:[],
                allfields:[],
                mainCtype:{},
                slugs:[],
                editedRow:false,
                filterAll:{},
                updateVal:false,
                inputValue:false,
                fillField:"",
                sendNid:false,
                fieldLabel:false,
                simpleModal:false,
                maincategory:null,
                tofilter:{},
                reciept: false,
                addbook:false,
                total: 0,
                slugfields:[],
                filteredInfo: null,
                columns,
                sortedInfo: null,
                showon: []
            };
        },
        computed: {
            filterProductsByName: function() {
                return this.data.filter(product => {
                    let i = 0;
                    var removefields = 0;
                   for (const [slug, value] of Object.entries(this.realFilter)) {

                       if(!value.value)
                       this.removeFilter(slug);

                        if(this.filterBy[value.slug])
                        {
                       if((value.value != "-" && value.value != "notassigned") && product[value.slug] !== null)
                       {
                       if(value.type === 12)
                       {

                           if(product[value.slug].includes(value.value) || product[value.slug] === value.value)
                           {
                               i = i + 1;
                           }

                       }
                       else {
                          let compareto = value.value;
                         if(value.value == "true")
                         compareto = true;
                         if(value.value == "false")
                         compareto = false;
                         if(product[value.slug] === compareto)
                        {
                            i = i + 1;
                        }
                       }
                       }
                       else {
if(value.type === 7 && (value.value == "notassigned") && (product[value.slug] === null || product[value.slug] === ""))
i = i + 1;
if(value.type === 7 && (value.value == "-" && (product[value.slug] === false || product[value.slug] === null || product[value.slug] === "" || product[value.slug] === true)))
i = i + 1;
if(value.type === 12 && (value.value == "-"))
i = i + 1;
if(value.type === 12 && (value.value == "notassigned" && (product[value.slug] === false || product[value.slug] === null || product[value.slug] === "" || product[value.slug] === true)))
i = i + 1;
                          // removefields = removefields + 1;
                       }
                    }
                    else
                    {
                       removefields = removefields + 1;
                    }
                    var size = 0 - removefields, key;
                    for (key in this.realFilter) {
                        if (this.realFilter.hasOwnProperty(key)) size++;
                    }  }
  if(this.realFilter.length == 0)
  return true;
                    if(size === i)
                    return true
                    else
                    return false
                })
            },
            rowSelection() {
                const { selectedRowKeys } = this;
                return {
                    selectedRowKeys,
                    onChange: this.onSelectChange,
                    hideDefaultSelections: true,
                    onSelection: this.onSelection,
                };
            },
        },
        mounted()

        {   dataService.axiosFetch("variable/site_url").then(results => {
                    this.vars = results;
                })
                dataService.axiosPost('getfilter', {ctype: this.id}).then((results) => {
this.tofilter = this.ff = results.data.data.filter;


dataService.axiosFetch("contenttypes").then(results => {
                    this.contenttypes = results;

                    results.forEach(obj => {
                        var iid = obj.id;
                        var vval = obj.title;
                        this.ctt.push({"text" : vval,"value" : iid});
                        if(obj.filters)
                        this.filterAll[iid] = obj.filters;
                        if(obj.slug === this.id)
                        {
                            this.thistype = obj.title;
                            this.tht = obj;

                          if(obj.filters)
                          {
                            this.filterBy = obj.filters;
                            let ke = Object.keys(obj.filters);
                            ke.forEach(j => {
                                this.filterValues[j] = "";
                            });
                           }
                            if(obj.fields)
                            {

                                this.columns = obj.fields;
                                this.columns.push(
                                    {
                                        title: 'Vytvorené',
                                        dataIndex: 'created',
                                        scopedSlots: {customRender: 'datetime'},
                                    },
                                    {
                                        align: 'right',
                                        slots: {'title': 'customTitle'},
                                        scopedSlots: {customRender: 'name'},
                                    }
                                );
                            }



                            if(obj.allfields)
                          {

                            //this.allfields = obj.allfields;
                            obj.allfields.forEach(obj => {
                                if(obj.slug == "weight")
                                this.allowreorder = true;
                                this.slugs.push(obj.slug);

                            if(obj.type == "1" || obj.type == "5" || obj.type == "6" || obj.type == "7" || obj.type == "4")
                            {
                                this.allfields.push({"value":obj.slug,"label":obj.title});
                            }
                            })
                          }




                            this.fieldtitle = vval;
                            this.slugfields = obj.id;
                            this.maincategory = obj.maincategory;

                            if(this.tid !== undefined) this.filt(this.tid,this.maincategory,12)
                        }
if(!this.filterBy)
    this.visible = false;
                    });


                });
            this.fetch();
                });
                dataService.axiosFetch("contenttype/" + this.id).then((ctype) => {

this.mainCtype = ctype;

for (let key in this.mainCtype.options) {
        // Loop through the array of options for each key
        this.mainCtype.options[key].unshift({value:'',label:'neurčené'});
      }

                })
        },
        methods: {
          updateValNid() {
  dataService.axiosPost('fieldupdate', {nid: this.sendNid, slug: this.fillField, value: this.inputValue, ct: this.id})
    .then(results => {
      if (results.data.success) {
        this.$message.success('Obsah bol upravený');

        // Update the data array immutably to trigger Vue's reactivity
        let newData = [...this.data];
        newData[this.editedRow] = {
          ...newData[this.editedRow],
          [this.fillField]: this.inputValue
        };

        this.data = newData; // Reassign the updated array to trigger reactivity

        // Close the modal and reset form fields
        this.simpleModal = false;
        this.fillField = "";
        this.sendNid = false;
        this.inputValue = "";
        this.tableKey += 1;
      }
    });
},
          editThisField(slug,nid,updateVal,iid)
          {

this.fillField = slug;
this.sendNid = nid;
this.inputValue = updateVal;
this.simpleModal = true;
this.editedRow = iid;

this.allfields.forEach(obj => {
  if(obj.value === this.fillField)
  {
    this.fieldLabel = obj.label;
  }
});
if (this.$refs.myInput) {
        this.$refs.myInput.focus();
      }

          },
          compareData(search,inslug)
            {
              let searchTerm =search.toLowerCase();
if(inslug.componentOptions.children[0].text.toLowerCase().includes(searchTerm))
return true;
            },
          applyFilter()
          {
            console.log(this.ff);

this.tofilter = this.ff;
dataService.axiosPost('savefilter', {filter:this.ff,ctype:this.id});
this.fetch();

          },
            openreceipt()
            {
this.reciept = true;
            },
            sendReciept()
            {},
            findItAll()
            {

var e = this.addbook;
              if(e.length > 2)
            {dataService.axiosPost('findnodes', {searchterm: e}).then(results => {

              this.searchResults = results.data.data;
            });
            }
              else
              {this.searchResults = [];}
console.log(this.searchResults);
              },
sendPartnerBook()
{


  dataService.axiosPost("partnerbook", {bookdata:this.partnerBookData}).then(results => {
    results;
    this.partner = false;
                  this.fetch();
                });


},
searchBook()
{
    console.log(this.addbook);
},
    importNode(e)  {
        let formData = new FormData();
          formData.append('upload', e.file);
          formData.append('ctype', this.id);
       var pa = this;
                this.axios.post(this.url.API_URL + '/import',
                    formData,
                    {
                        headers: authHeader(true)
                    }
                ).then(function (data) {
 pa.data.unshift(...data.data.data);
                });

                },
                doAction()
                {
                    if(this.action)
                    {
                    dataService.axiosPost('action/' + this.action, { 'nodes' : this.rowSelection.selectedRowKeys}).then((result) => {
                        this.rowSelection.selectedRowKeys = [];

                        if(this.action === "delete")
                        this.fetch();
                        if(this.action === "export")
                        {


        const blob = new Blob([JSON.stringify(result.data.data)], { type: 'application/json' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'export-' + this.id;
        link.click()
        URL.revokeObjectURL(link.href)
                        }




                    });
                   }
                },
        confirm(e) {
                dataService.axiosDelete('content', e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            hasValue(obj, key, value) {
                return obj.hasOwnProperty(key) && obj[key] === value;
            },
            multiedit()
            {
                this.$router.push({ name: 'multiedit', query: { id:this.id, nids: this.rowSelection.selectedRowKeys.join(','), acfields: this.actionFields.join(',') } });
            },
            removeFilter(index)
            {
                this.realFilter.splice(index, 1);
                localStorage.setItem('filter', JSON.stringify(this.realFilter));
            },
            filt(w,slug,type)
            {
                 for (var key in this.realFilter) {
                   if (this.realFilter[key]["slug"] === slug)
                   {
                        delete this.realFilter[key];
                   }
                  }
             let filtered =  this.realFilter.filter(function () { return true });
             this.realFilter = filtered;
             this.realFilter.push({"slug": slug, 'type': parseInt(type), "value": w});
             localStorage.setItem('filter', JSON.stringify(this.realFilter));
             },
            cancel() {
            },
            onSelectChange(selectedRowKeys) {
                this.selectedRowKeys = selectedRowKeys;
            },
            filterIt()
            {
            },
            handleChange(pagination, filters, sorter) {
                this.filteredInfo = filters;
                this.sortedInfo = sorter;
            },
            clearFilters() {
                this.filteredInfo = null;
            },
            hide() {
                this.visible = false;
            },
            handleTableChange(pagination, filters, sorter) {
filters;
sorter;
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current
      });
    },
            fetch(params = {results: this.pagination.pageSize, page: 0}) {
                this.loading = true;

                  dataService.axiosPost('content/' + this.id + '/true/' + (params.page > 0 ? params.page-1 : 0) + '/' + params.results,this.tofilter).then(results => {
                   this.totalCount = parseInt(results.data.data.pop());
                   this.data = results.data.data;

 const pagination = { ...this.pagination };
        pagination.total = this.totalCount;
        pagination.pageSize = params.results;
        this.pagination = pagination;

                    this.loading = false;
                });
            }

        },
    };
</script>

<style>
ul.ant-pagination {
margin-top: 0;
margin-bottom: 1em;
}
.ant-carousel >>> .slick-slide {
text-align: center;
height: 160px;
line-height: 160px;
background: #364d79;
overflow: hidden;
}
#app .smaller .ant-select-selection__rendered,
#app .smaller .ant-select-selection--single
{line-height:40px;height:40px;margin-right:10px;}
.system-options
{width:25%;}
.ant-carousel .custom-slick-arrow {
width: 25px;
height: 25px;
line-height: 20px;
font-size: 14px;
color: #fff;
background-color: rgba(31, 45, 61, 0.41);
border-radius: 100%;
opacity: 0.9;
top:43%;
padding: 4px;
padding-left: 8px;
}
.ant-carousel .custom-slick-arrow:before
{display:none}
.ant-carousel .custom-slick-arrow:hover {
opacity: 0.5;
color:#fff;
background: #2d2d2d;
}

.ant-carousel .slick-slide img {
width:100%;
}
.ant-popover-inner-content
{max-width:500px;}

.actions
{display: block;
width: 175px;
float:right;
text-align: right;}

.extra-link
{
    display:inline-block;
}
.square{
display:block;
width:20px;
height:20px;
}
.nopad a
{padding:0;}

.infield-gallery-image
{float:left;
width:50%;
}
.infield-gallery-image img
{width:100%;}
.ant-pagination-item {
border: 0;
}

.ant-table {
border-bottom: 1px solid rgba(0, 0, 0, 0.05);
margin-bottom: 20px;
}
.filter-block .remove-filter
{cursor:pointer;}
.filter-block
{background-color: #fafafa;
border-top-right-radius:10px;
margin-bottom:-10px;
position:relative;z-index:99;
border-top-left-radius:10px;
padding:10px;text-align:right;}

.quick-links label
{font-size:12px;line-height:35px;}
.extramuted
{border-color: #deebff;font-size:12px;
background-color:#fff;}
.extramuted.selected
{border-color:#5e72e4}
.extramuted:hover
{background-color: #5e72e4;
color:#fff!important;}

.ant-dropdown-open .btn
{    background-color: #5e72e4;
color:#fff!important;
border-color: #5e72e4;}

</style>
